<script lang="ts">
  import type { LayoutData } from "./$types"
  import { SiteFooter, SiteHeader } from "$lib/components/centralci/index.js"

  export let data: LayoutData
</script>

<div class="min-h-screen bg-background font-sans antialiased flex flex-col">
  <SiteHeader session={data.session} />
  <div class="mx-auto flex flex-1 overflow-hidden self-stretch size-full">
    <slot></slot>
  </div>
  <SiteFooter />
</div>
